<template>
<div class="main-authentication">
    <ui-card class="main-authentication__card mx-auto mb-5">
        <div class="text-center">
            <router-link to="/login">
                <img class="w-50" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
            </router-link>
        </div>
        <h5 class="text-center">Two Step Verification</h5>
        <p class="text-center">Open the two-step verification app on your mobile device to get your verification code.</p>
        <form class="main-authentication__form" @submit.prevent="validateCode">
            <ui-input
                id="code"
                name="code"
                label="Code"
                type="String"
                placeholder="Enter code"
                v-model:value="code"
                :field="v$.code"
            />
            <ui-button 
                :loading="loading"
                class="w-100"
                type="submit"
                variant="primary"
            >
                Continue
            </ui-button>
            <ui-button :loading="sendingEmail" @click="sendEmail">
                Send me code
            </ui-button>
        </form>
    </ui-card>
    <p class="main-authentication__copy">
        &copy; 2022 EcartPay. All rights reserved.
    </p>
    <img class="main-authentication__hero" src="@/assets/img/login/hero.png" alt="Ecart Pay" />
</div>
</template>

<script>

import { useVuelidate } from '@vuelidate/core';
import { required, helpers, numeric } from '@vuelidate/validators';

import UiCard from '@/components/ui/Card.vue';
import UiInput from '@/components/ui/Input.vue';
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    components: { UiCard, UiInput, UiButton },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            sendingEmail: false,
            code: '',
        };
    },
    validations() {
        return {
            code: {
                required: helpers.withMessage('The code is required.', required),
                exactLength: helpers.withMessage('The code must be 6 characters long.', value => value.length === 6),
                numeric: helpers.withMessage('The code must be numeric', value => !isNaN(value)),
                $autoDirty: true,
            },
        };
    },
    watch: {
        async code() {
            if (!await this.v$.$validate()) {
                return;
            }

            this.validateCode();

        }
    },
    methods: {
        async validateCode(){
            try {
                this.loading = true;

                await this.$axios.post('/_/otp/validate', { token: this.code });
                
                this.$toast.success('Code verify.');
                let status = await this.expiredPassworStatus();
                if(status){
                    this.$router.push('/expired-password');
                } else {
                    this.$router.push('/accounts');
                }
            } catch(error) {	
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
        async sendEmail(){
            try {
                this.sendingEmail = true;

                await this.$axios.post('/_/otp/recovery-token');

                this.$toast.success('Email send successfully');
            } catch(error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingEmail = false;
            }
        },
        async expiredPassworStatus(){
            try {
                const { data } = await this.$axios.get('/_/passwords/active');
                return data; 
            } catch (error){
                return false
            }
        }
    },
};
</script>

<style lang="scss">
.main-authentication {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 3rem 0;
    position: relative;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        width: 100%;
        max-width: 480px;
        position: relative;
        z-index: 2;

        @media(max-width: 576px) {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__form{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__title {
        color: $general-black;
        font-weight: 500;
        font-size: 16px;
    }

    &__link {
        color: $ecart-accent;
        font-weight: 500;
        text-decoration: none;
        justify-content: center;
    }

    &__copy {
        color: rgba($general-black, 0.7);
        position: relative;
        z-index: 2;
    }

    &__hero {
        bottom: 0;
        height: auto;
        position: absolute;
        right: 0;
        width: 35vw;
        z-index: 1;

        @media(max-width: 576px) {
            display: none;
        }
    }
}
</style>